import React from 'react'
import { Link } from 'gatsby'

const Footer = () => (
  <div className="bg-black border-t border-grey-darkest">

    <div className="container mx-auto text-white flex justify-between">
      <div className="py-8">
        <ul className="list-reset">
          <li className="inline-block p-4 mx-4 ml-0"><Link to="/support" className="text-grey hover:text-white no-underline ">Support</Link></li>
          <li className="inline-block p-4 mx-4"><Link to="/privacy" className="text-grey hover:text-white no-underline ">Privacy</Link></li>
          <li className="inline-block p-4 mx-4"><Link to="/terms" className="text-grey hover:text-white no-underline">Terms</Link></li>
        </ul>
      </div>
      <div className="py-8 text-right">
        <div className="pt-4 pb-2">Recent announcements</div>
        <ul className="list-reset text-sm">
          <li className="py-1"><span className="text-grey-dark">September 2019</span> <a href="https://twitter.com/whoopsio/status/1178365055773089798" className="text-grey-light hover:text-white no-underline ">Multi-cloud agents for all accounts</a></li>
          <li className="py-1"><span className="text-grey-dark">April 2019</span> <Link to="/article/onprem-agents-private-beta" className="text-grey-light hover:text-white no-underline ">On-premise agents in private beta</Link></li>
          <li className="py-1"><span className="text-grey-dark">March 2019</span> <a href="https://twitter.com/whoopsio/status/1100827704113414144" className="text-grey-light hover:text-white no-underline ">Invite team members to your workspace</a></li>
          <li className="py-1"><span className="text-grey-dark">Feb 2019</span> <Link to="/article/beta-launch" className="text-grey-light hover:text-white no-underline ">Public beta launch</Link></li>
        </ul>
      </div>
    </div>
  </div>
)

export default Footer
