import React from 'react'
import { Link } from 'gatsby'

const Header = ({ siteTitle }) => (
  <div className="bg-black border-t-2 border-pink">
    <div className="container m-auto">
      <div className="px-4 py-10">
        <h4 className="m-0 p-0">
          <Link to="/" className="text-white no-underline">
            {siteTitle} <sup className="bg-pink text-white rounded-full p-1 text-normal">Beta</sup>
          </Link>

            <a href="https://app.whoops.io/signup" className="text-white no-underline border-pink font-medium p-2 px-4 -my-2 mx-2 rounded-full border sm:float-right text-sm hover:bg-grey-darkest">
                Sign up
            </a>

          <a href="https://app.whoops.io/login" className="text-white no-underline font-medium p-2 px-4 -my-2 mx-2 mr-2 rounded-full sm:float-right text-sm hover:bg-grey-darkest">
                      Login
          </a>
        </h4>
      </div>
    </div>
  </div>
)

export default Header
