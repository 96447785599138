import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faHeartbeat, faClock, faCode, faUsers, faBell, faChartBar, faComment, faEnvelope, faShieldAlt } from '@fortawesome/free-solid-svg-icons'

import Header from './header'
import Footer from './footer'
import './layout.css'

library.add(faHeartbeat, faClock, faCode, faUsers, faBell, faChartBar, faComment, faEnvelope, faShieldAlt)

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <div className="font-sans bg-black min-h-screen">
          <Header siteTitle={data.site.siteMetadata.title} />
          <div className="bg-grey-lighter">
            {children}
          </div>
          <Footer />
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
